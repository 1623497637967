













import { Component, Vue } from "vue-property-decorator";
import { RouterNames } from "../../../router/routernames";
@Component({
  components: {
    EmpresarialOpciones: () => import("./empresarial-opciones.vue")
  }
})
export default class DashHomeEmpresarial extends Vue {}
